import React from 'react';

import { ExternalLink } from 'components/common';

const GreyNoiseAdapterDocumentation = () => {
  const style = { marginBottom: 10 };
  return (
      <div>
        <p style={style}>
            The Greynoise data adapter uses the <ExternalLink href="https://developer.greynoise.io/">Greynoise API</ExternalLink> to
            lookup indicators for the given key and returns the values for the  IP Context endpoint.
            See <ExternalLink href="https://developer.greynoise.io/reference/ip-lookup-1#noisecontextip-1">IP Context</ExternalLink>
        </p>
      </div>
  );
};

export default GreyNoiseAdapterDocumentation;
